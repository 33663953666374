<app-header [fullMenu]="false"></app-header>
<div class="container bg-light position-relative password-reset">
    <div [hidden]="success" class="row p-4 justify-content-center">
        <div class="col-5">
            <h1 class="h2 text-uppercase mb-4">{{ 'web.password_reset' | translate }}</h1>
        </div>
        <div class="col">
            <form [formGroup]="form.getGroup()" class="smallx">
                <div
                    class="alert alert-danger"
                    role="alert"
                    *ngIf="form.getGroup().errors && (form.getGroup().touched || form.getGroup().dirty)"
                >
                    {{ form.getErrorMessage(form.getGroup()) | translate }}
                </div>

                <div class="form-group row">
                    <label for="input1" class="col-sm-4 col-form-label">{{ 'web.email' | translate }}</label>
                    <div class="col-sm-8">
                        <input
                            matInput
                            placeholder="{{ 'web.enter_email' | translate }}"
                            formControlName="email"
                            class="form-control form-control-sm"
                        />
                        <mat-error *ngIf="form.email?.invalid && !form.email?.pristine">{{
                            form.getErrorMessage(form.email) | translate
                        }}</mat-error>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="input1" class="col-sm-4 col-form-label">{{ 'web.password' | translate }}</label>
                    <div class="col-sm-8">
                        <input
                            matInput
                            type="password"
                            placeholder="{{ 'web.enter_password' | translate }}"
                            formControlName="password"
                            class="form-control form-control-sm"
                        />
                        <mat-error *ngIf="form.password?.invalid && !form.password?.pristine">{{
                            form.getErrorMessage(form.password) | translate
                        }}</mat-error>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="input1" class="col-sm-4 col-form-label">{{ 'web.password_repeat' | translate }}</label>
                    <div class="col-sm-8">
                        <input
                            matInput
                            type="password"
                            placeholder="{{ 'web.enter_password_again' | translate }}"
                            formControlName="password_confirmation"
                            class="form-control form-control-sm"
                        />
                        <mat-error *ngIf="form.passwordConfirmation?.invalid && !form.passwordConfirmation?.pristine">{{
                            form.getErrorMessage(form.passwordConfirmation) | translate
                        }}</mat-error>
                    </div>
                </div>

                <p>
                    <ngx-recaptcha2
                        #captchaElem3
                        [siteKey]="siteKey"
                        (success)="handleSuccessRC($event)"
                        [useGlobalDomain]="false"
                        [hl]="lang.getLangSnapshot()"
                        [theme]="recaptchaTheme"
                        [type]="'Checkbox'"
                        formControlName="recaptcha"
                    >
                    </ngx-recaptcha2>

                    <span class="text-danger" *ngIf="form.recaptcha?.invalid">{{
                        form.getErrorMessage(form.recaptcha) | translate
                    }}</span>
                </p>
                <p>
                    <button class="btn btn-primary rounded-0" [disabled]="form.getGroup().invalid" (click)="save()">
                        {{ 'web.' + submitText | translate }}
                    </button>
                    &nbsp;&nbsp;
                    <button class="btn btn-outline-secondary" (click)="resetForm()">
                        {{ 'web.reset_form' | translate }}
                    </button>
                </p>
            </form>
        </div>
    </div>

    <div *ngIf="success" class="row p-4 justify-content-center">
        <div class="row mt-4">
            <div class="col align-self-center text-center">
                <p class="text-success">
                    {{ 'web.reset_password_success' | translate }}
                    <br />
                    <i class="material-icons"> check_circle_outline </i>
                </p>

                <p>
                    <button
                        [routerLink]="['/page/account/dashboard']"
                        queryParams="{ signin: 1 }"
                        class="btn btn-primary rounded-0"
                    >
                        {{ 'web.sign_in' | translate }}
                    </button>
                </p>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
