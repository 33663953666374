/**
 *  Reset password form
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import {
    FormControl,
    Validators,
    FormGroup,
    AbstractControl,
} from '@angular/forms';

import { BaseForm } from './base.form';

@Injectable({
    providedIn: 'root',
})
export class ResetPasswordForm extends BaseForm {
    public constructor() {
        super();
        this.init();
    }

    /**
     * Initialize form Controls
     * @return {void}
     */
    protected init(): void {
        super.init();
        const PASS_CONFIRM = 'password_confirmation';
        this.formGroup = new FormGroup(
            {
                email: new FormControl('', [
                    Validators.required,
                    Validators.email,
                ]),
                password: new FormControl('', [
                    Validators.required,
                    Validators.minLength(6),
                ]),
                [PASS_CONFIRM]: new FormControl('', [Validators.required]),
                recaptcha: new FormControl('', Validators.required),
                code: new FormControl(''),
            },
            {
                validators: this.mustHaveSameValueValidator(
                    'password',
                    'password_confirmation',
                    'passwordMatch',
                ),
            },
        );
    }

    public get email(): AbstractControl | null {
        return this.formGroup.get('email');
    }

    public get recaptcha(): AbstractControl | null {
        return this.formGroup.get('recaptcha');
    }

    public get password(): AbstractControl | null {
        return this.formGroup.get('password');
    }

    public get passwordConfirmation(): AbstractControl | null {
        return this.formGroup.get('password_confirmation');
    }

    public get code(): AbstractControl | null {
        return this.formGroup.get('code');
    }
}
