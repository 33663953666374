import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ComponentModule } from '@components/component.module';

import { ResetPasswordComponent } from './reset-password.component';
import { ResetPasswordRoutingModule } from './reset-password-routing.module';

import { SharedModule } from '@/shared/shared.module';

@NgModule({
    declarations: [ResetPasswordComponent],
    imports: [CommonModule, SharedModule, ResetPasswordRoutingModule, ComponentModule],
})
export class ResetPasswordModule { }
