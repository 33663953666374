import { Component, OnInit, ViewChild } from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InfoService } from 'src/app/services/info.service';
import { UserService } from 'src/app/services/user.service';
import { ResetPasswordForm } from 'src/app/shared/forms/reset-password.form';
import { LangService } from 'src/app/services/lang.service';
import APP_CONFIG from 'src/app/config/app.config';
import { ResetPasswordInterface } from 'src/app/interfaces/reset-password.interface';
import { TranslationService } from 'src/app/services/translation.service';

import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {
    @ViewChild('captchaElem3', { static: true })
    public captcha: any;

    public siteKey: string = APP_CONFIG.recaptchaSiteKey;

    public recaptchaTheme: string = 'light';

    public success: boolean = false;

    public submitText: string = 'change_password';

    public constructor(
        protected router: ActivatedRoute,
        protected page: PageService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        protected info: InfoService,
        protected user: UserService,
        public lang: LangService,
        public form: ResetPasswordForm,
    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        TranslationService.isLoaded = false;

        this.setTitle(
            {
                title: this.translate.instant('web.title_reset_password'),
                description: this.translate.instant(
                    'web.description_reset_password',
                ),
            },
            true,
        );
    }


    public handleSuccessRC(token: string): void {
        this.form.patchValue({
            recaptcha: token,
            code: this.router.snapshot.paramMap.get('code'),
        });
    }

    /**
     * Register user
     * @return {void}
     */
    public save(): void {
        this.submitText = 'form_loading_text';
        this.form.getGroup().disable();
        this.user
            .resetPassword<ResetPasswordInterface>(this.form.getGroup().value)
            .subscribe(
                (): void => {
                    this.resetForm();
                    this.success = true;
                },
                (er): void => {
                    this.form.getGroup().enable();
                    this.captcha.resetCaptcha();
                    this.submitText = 'submit_form';
                    this.form.setErrors(er);
                },
            );
    }

    /**
     * Reset forn
     */
    public resetForm(resetCaptcha: boolean = true): void {
        this.form.getGroup().enable();
        this.form.getGroup().reset();
        if (resetCaptcha) { this.captcha.resetCaptcha(); }
        this.submitText = 'change_password';
        this.success = false;
    }
}
